<template>
  <div class="main-wrapper">
    <div class="claim-wrapper">
      <div class="step3-div">
        <FuNav
          :fixed="false"
          :title="$t('ebClaim.UpdateRevision')"
          :arrow="true"
          percent
        />

        <FuFormMin
          v-if="finished"
          ref="FuForm"
          v-model="formData"
          class="p10"
          :schema="formColumns"
          :lang="lang"
        />

        <div class="btn-group">
          <div class="btn-group-wapper">
            <Button
              class="fu-v2-btn block"
              round
              block
              type="danger"
              @click="$parent.step = 1"
            >{{ $t('ebClaim.Back') }}</Button>
            <span />
            <Button
              class="fu-v2-btn danger block"
              round
              block
              type="danger"
              @click="submitApplication"
            >{{ $t('ebClaim.Next') }}</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
  

</template>

<script>
import FuNav from '@/components/v2/FuNav'
import FuFormMin from '@/components/FuFormMin'
import { Button } from 'vant'
import { queryClaimDetailsInfo, queryReportDetailInfo, submittApplicationForm } from '@/views/claim/api/index.js'
import { toastLoading } from '@/utils'
import('@/views/claim/eb/css/reset.css')
export default {
  components: { FuFormMin, Button, FuNav },
  inject: ['getStep1Data', 'getPolicyItem', 'getStep2Data', 'getReportInfo'],
  props: {},
  data() {
    return {
      eventValue: '',
      eventOptions: [
        { label: this.$t('Inpatient'), value: 0 },
        { label: this.$t('Passport'), value: 0 },
        { label: this.$t('Dental'), value: 0 },
        { label: this.$t('Surgary'), value: 0 },
        { label: this.$t('Maternity'), value: 0 },
        { label: this.$t('Special Case'), value: 0 }
      ],
      active: '1',
      loading: false,
      formData: {},
      formColumns: {},
      showIframe: false,
      pdfUrl: '',
      fileKey: '',
      finished: false,
      submitObj: {
        formNo: '',
        reportUid: ''
      }
      
    }
  },
  computed: {
    lang() {
      return this.$store.state.base.lang
    }
  },
  activated() {
    this.init()
  },
  methods: {
    async init() {
      this.formData = {}
      this.formColumns = {}
      const { registerUid } = this.$route.query
      if (registerUid) {
        this.queryClaimDetailsInfo()
      } else {
        this.queryReportDetailInfo()
      }
    },
    async queryReportDetailInfo() {
      this.loading = toastLoading()
      const { reportUid, reportPolicyUid } = this.$route.query
      const params = { reportUid, reportPolicyUid }
      const res = await queryReportDetailInfo(params)
      this.submitObj = {
        formNo: res[0].formNo, // TODO:
        reportUid: res[0].reportUid
      }
      const formAndData = res.filter(item => {
        return item.formData
      }).map(item => {
        item.formatFormSchema = JSON.parse(JSON.parse(item.formSchema).displaySchema)
        item.formatFormData = item.formData ? JSON.parse(item.formData) : {}
        return item
      })
      // console.log(formAndData[0].formData)
      this.formData = JSON.parse(formAndData[0].formData || '{}')
      this.formColumns = JSON.parse(JSON.parse(res[0].formSchema || '{}').displaySchema || '{}')
      this.finished = true
      this.loading.clear()
      this.loading = null
    },
    async queryClaimDetailsInfo() {
      this.loading = toastLoading()
      const { registerUid } = this.$route.query
      const res = await queryClaimDetailsInfo({ registerUid })
      this.submitObj = {
        formNo: res[0].formNo, // TODO:
        reportUid: res[0].reportUid
      }
      const formAndData = res.filter(item => {
        return item.formData
      }).map(item => {
        item.formatFormSchema = JSON.parse(JSON.parse(item.formSchema).displaySchema)
        item.formatFormData = item.formData ? JSON.parse(item.formData) : {}
        return item
      })
      // console.log(formAndData[0].formData)
      this.formData = JSON.parse(formAndData[0].formData || '{}')
      this.formColumns = JSON.parse(JSON.parse(res[0].formSchema || '{}').displaySchema || '{}')
      this.finished = true
      this.loading.clear()
      this.loading = null
    },
    formValid() {
      return new Promise((resolve) => {
        this.$refs.FuForm.validate().then(res => {
          resolve(true)
        }).catch(() => {
          resolve(false)
        })
      })
    },
    async submitApplication() {
      const result = await this.formValid()
      if (!result) return
      const formDataJson = JSON.stringify(this.formData)
      const { registerUid } = this.$route.query
      const params = {
        formDataJson, formNo: this.submitObj.formNo, registerUid, reportUid: this.submitObj.reportUid
      }
      this.loading = toastLoading()
      const res = await submittApplicationForm(params)
      if (res.claimFormId) {
        this.$router.push({ name: 'EbClaimSubmitSuccess' })
      }
      this.loading && this.loading.clear()
      this.loading = null
    }
  }
}
</script>
<style lang="scss" scoped>
.main-wrapper {
  height: 100vh;
  width: 100vw;
  background: #f5f5f5;
}
@media (min-width: 600px) {
  .claim-wrapper {
    width: 600px;
  }
}
.claim-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
  height: 100vh;
  position: relative;
}
.event-wapper {
  padding: 10px;
  border-bottom: 8px solid #f5f5f5;
  justify-content: space-between;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  .event-item {
    text-align: center;
    width: 48%;
    border: 1px solid #f5f5f5;
    padding: 10px;
  }
  .event-item-active {
    border: 1px solid #E28800;
    background-color: #fff3e1;
    color: #E28800;
  }
}
.step3-div {
  padding-bottom: 50px;
  overflow-y: scroll;
}
.btn-group {
  padding: 15px;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  .btn-group-wapper {
    display: flex;
    gap: 10px;
  }
}
.iframe-div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 30px;
  right: 0;
  background-color: #fff;
  z-index: 99;
  .fu-v2-iframe {
    width: 100%;
    height: 100%;
    padding-bottom: 50px;
  }
}
</style>
